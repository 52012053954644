import "@olxbr/ds-web/lib/styles.css"
import { useEffect, useState } from "react";
import { Flex, LayoutContainer, Link, LogoOLX, Text } from "@olxbr/ds-web"

const App = () => {
  const [count, setCount] = useState(20);

  useEffect(() => {
    if (count === 0) return;

    const interval = setInterval(() => {
      setCount(count => count - 1);
    }, 1000);
    return () => {
      clearInterval(interval)
      if (count === 1) redirect();
    };
  }, [count]);

  const redirect = () => {
    window.location.href = "https://planoprofissional.olx.com.br/"
  };

  return (
    <LayoutContainer tag="main">
      <Flex flexDirection="column" alignItems="center" rowGap="--spacing-1"  style={{textAlign: "center"}}>
        <div style={{ width: "250px" }}>
          <LogoOLX variation="default" />
        </div>
        <Flex flexDirection="column" alignItems="center">
          <Text.SubtitleLarge>
            Caro cliente, informamos que a ferramenta OLX Pro <strong>foi descontinuada</strong>.
          </Text.SubtitleLarge>
          <Text.SubtitleLarge>
            Visando trazer uma experiência melhor, estamos concentrando todas as suas ferramentas profissionais dentro da plataforma principal OLX.
          </Text.SubtitleLarge>
        </Flex>
        <Text.SubtitleMedium>
          Ficou com alguma dúvida? <Link href="https://ajuda.olx.com.br/s" size="large">Saiba mais</Link>
        </Text.SubtitleMedium>
      </Flex>

      <Flex flexDirection="column" alignItems="center" rowGap="--spacing-1" marginTop="--spacing-10" style={{textAlign: "center"}}>
        <Text.SubtitleMedium>
          Você será <strong>redirecionado automaticamente</strong> para a OLX em <Text.SubtitleMedium color="--color-feedback-error-100" display="inline"><strong>{count}</strong></Text.SubtitleMedium> segundos…
        </Text.SubtitleMedium>
        <Text.SubtitleMedium>
          Caso não tenha sido redirecionado, <Link href="https://planoprofissional.olx.com.br/" size="large">clique aqui</Link>
        </Text.SubtitleMedium>
      </Flex>
    </LayoutContainer>
  );
}

export default App;
